<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('evoucher.batchJob.title')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md4>
                  <v-select
                    v-model="batchJob"
                    :items="batchJobs"
                    :label="$t('evoucher.batchJob.batchJobs')"
                    @change="batchJobChange"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-select
                    v-model="scheduleExcute"
                    :items="schedules"
                    :label="$t('evoucher.batchJob.scheduleExecute')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="updateScheduleExecute"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-ok" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.save")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
export default {
  data: () => ({
    isLoading: false,
    batchJobs: [],
    batchJob: null,
    scheduleExcute: null,
    schedules: stringUtils.ScheduleExecuteList
  }),
  computed: {
    ...mapGetters([
      'BATCH_JOB_DATA',
      'SCHEDULE_EXECUTE_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    BATCH_JOB_DATA () {
      let data = this.BATCH_JOB_DATA
      for (let i = 0, size = data.length; i < size; i++) {
        this.batchJobs.push({ value: data[i].id, text: data[i].name, crontab_id: data[i].crontab_id })
      }
      if (this.batchJobs.length > 0) {
        this.batchJob = this.batchJobs[0].value
        this.scheduleExcute = this.batchJobs[0].crontab_id
      }
    }
  },
  created () {
    this.GET_BATCH_JOBS()
  },
  methods: {
    /**
     * Batch job change
     */
    batchJobChange: function (batchJobId) {
      let batchJobSelected = this.batchJobs.filter(element => {
        return element.value === batchJobId
      })
      this.scheduleExcute = batchJobSelected[0].crontab_id
    },
    /**
     * Update schedule execute
     */
    updateScheduleExecute: function () {
      let filter = {
        periodictTaskId: this.batchJob,
        scheduleExecuteId: this.scheduleExcute
      }
      this.isLoading = true
      this.UPDATE_SCHEDULE_EXECUTE_FOR_BATCH_JOB(filter).then(
        function () {
          this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_BATCH_JOBS',
      'GET_SCHEDULE_EXECUTE_LIST',
      'UPDATE_SCHEDULE_EXECUTE_FOR_BATCH_JOB',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style></style>
